<template>
  <router-view />
</template>
<script>
import {watch} from 'vue'
import $ from 'jquery'
import { useStore } from 'vuex'
export default {
  mounted() {
    const store = useStore()
    watch(
      () => store.getters.isShowModal,
      val => {
        if (val) {
          $('html, body').css({
            overflow: 'hidden',
            height: '100%',
          })
        } else {
          $('html, body').css({
            overflow: 'auto',
            height: 'auto',
          })
        }
      }
    )
  },
}
</script>
