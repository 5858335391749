import {
  CognitoUserPool,
  CognitoUser,
  AuthenticationDetails,
  CognitoUserAttribute,
} from 'amazon-cognito-identity-js'
import {Config, CognitoIdentityCredentials} from 'aws-sdk'

class Cognito {
  constructor(
    userPoolId = process.env.VUE_APP_AWS_USER_POOL_ID,
    clientId = process.env.VUE_APP_AWS_CLIENT_ID,
    region = process.env.VUE_APP_AWS_REGION
  ) {
    this.userPool = new CognitoUserPool({UserPoolId: userPoolId, ClientId: clientId})
    Config.region = region

    this.User = null
  }

  // ログイン
  authenticateUser(email, password) {
    const userData = {Username: email, Pool: this.userPool}
    const cognitoUser = new CognitoUser(userData)
    const authenticationData = {Username: email, Password: password}
    const authenticationDetails = new AuthenticationDetails(authenticationData)
    return new Promise((resolve, reject) => {
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: result => {
          resolve(result)
        },
        onFailure: err => {
          err.message = this.translation(err.message)
          reject(err)
        },
      })
    })
  }

  // 新規作成
  signUp(name, email, password, birthday) {
    const attributeList = []
    const birthDate = {Name: 'birthdate', Value: birthday}
    const emailData = {Name: 'email', Value: email}
    const nameData = {Name: 'name', Value: name}

    attributeList.push(new CognitoUserAttribute(birthDate), new CognitoUserAttribute(emailData), new CognitoUserAttribute(nameData))

    return new Promise((resolve, reject) => {
      this.userPool.signUp(email, password, attributeList, null, (err, result) => {
        if (err) {
          err.message = this.translation(err.message)
          reject(err)
          return
        }

        resolve(result)
      })
    })
  }

  // 認証コード
  confirmRegistration(email, code) {
    const userData = {Username: email, Pool: this.userPool}
    const cognitoUser = new CognitoUser(userData)

    return new Promise((resolve, reject) => {
      cognitoUser.confirmRegistration(code, true, (err, result) => {
        if (err) {
          err.message = this.translation(err.message)
          reject(err)
        } else {
          resolve(result)
        }
      })
    })
  }

  // パスワード忘れメール送信
  forgetPassword(email) {
    const userData = {Username: email, Pool: this.userPool}
    const cognitoUser = new CognitoUser(userData)
    return new Promise((resolve, reject) => {
      cognitoUser.forgotPassword({
        onSuccess: result => {
          resolve(result)
        },
        onFailure: err => {
          err.message = this.translation(err.message)
          reject(err)
        },
      })
    })
  }

  // パスワードリセット
  confirmPassword(email, newPassword, code) {
    const userData = {Username: email, Pool: this.userPool}
    const cognitoUser = new CognitoUser(userData)
    return new Promise((resolve, reject) => {
      cognitoUser.confirmPassword(code, newPassword, {
        onSuccess: result => {
          resolve(result)
        },
        onFailure: err => {
          err.message = this.translation(err.message)
          reject(err)
        },
      })
    })
  }

  // 認証コード再送信
  resendConfirmationCode(email) {
    const userData = {Username: email, Pool: this.userPool}
    const cognitoUser = new CognitoUser(userData)

    return new Promise((resolve, reject) => {
      cognitoUser.resendConfirmationCode((err, result) => {
        if (err) {
          err.message = this.translation(err.message)
          reject(err)
        } else {
          resolve(result)
        }
      })
    })
  }

  // 属性の取得
  getUserAttributes() {
    const currentUser = this.userPool.getCurrentUser()

    return new Promise((resolve, reject) => {
      if (!currentUser) {
        reject()
        return
      }
      currentUser.getSession((err, session) => {
        if (err) {
          reject(err)
          return
        }

        if (!session.isValid()) {
          reject(session)
          return
        }

        currentUser.getUserAttributes((err, result) => {
          if (err) {
            reject(err)
            return
          }

          resolve(result)
        })
      })
    })
  }

  // プロフィール更新
  updateAttributes(attributes) {
    const attributeList = []
    for (const key in attributes) {
      const attribute = {Name: key, Value: attributes[key]}
      attributeList.push(new CognitoUserAttribute(attribute))
    }
    return new Promise((resolve, reject) => {
      const currentUser = this.userPool.getCurrentUser()

      if (!currentUser) {
        reject()
      }

      currentUser.getSession((err, session) => {
        if (err) {
          reject(err)
          return
        }

        if (!session.isValid()) {
          reject(session)
          return
        }

        currentUser.updateAttributes(attributeList, (err, result) => {
          if (err) {
            err.message = this.translation(err.message)
            reject(err)
            return
          }

          resolve(result)
        })
      })
    })
  }

  // ユーザー更新認証コード
  confirmUpdate(hasPhoneNumber, code) {
    return new Promise((resolve, reject) => {
      const currentUser = this.userPool.getCurrentUser()

      if (!currentUser) {
        reject()
      }

      currentUser.getSession((err, session) => {
        if (err) {
          reject(err)
          return
        }

        if (!session.isValid()) {
          reject(session)
          return
        }

        const attributeName = hasPhoneNumber ? 'phone_number' : 'email'
        currentUser.verifyAttribute(attributeName, code, {
          onSuccess: result => {
            resolve(result)
          },
          onFailure: err => {
            err.message = this.translation(err.message)
            reject(err)
          },
        })
      })
    })
  }

  getAttributeVerificationCode(key) {
    return new Promise((resolve, reject) => {
      const currentUser = this.userPool.getCurrentUser()

      if (!currentUser) {
        reject()
      }
      currentUser.getSession((err, session) => {
        if (err) {
          reject(err)
          return
        }

        if (!session.isValid()) {
          reject(session)
          return
        }

        currentUser.getAttributeVerificationCode(key, {
          onSuccess: result => {
            resolve(result)
          },
          onFailure: err => {
            err.message = this.translation(err.message)
            reject(err)
          },
        })
      })
      
    })
  }

  verifyAttribute(key, code) {
    return new Promise((resolve, reject) => {
      const currentUser = this.userPool.getCurrentUser()

      if (!currentUser) {
        reject()
      }
      currentUser.getSession((err, session) => {
        if (err) {
          reject(err)
          return
        }

        if (!session.isValid()) {
          reject(session)
          return
        }

        currentUser.verifyAttribute(key, code, {
          onSuccess: result => {
            resolve(result)
          },
          onFailure: err => {
            err.message = this.translation(err.message)
            reject(err)
          },
        })
      })
      
    })
  }

  translation(message) {
    const conversions = {
      'Password did not conform with policy: Password not long enough':
        'パスワードは8文字以上にしてください',
      'Password did not conform with policy: Password must have uppercase characters':
        'パスワードは8文字以上、大文字小文字を含む英数字を指定してください',
      "2 validation error detected: Value at 'password' failed to satisfy constraint: Member must satisfy regular expression pattern: ^[\\S]+.*[\\S]+$":
        'パスワードは8文字以上、大文字小文字を含む英数字を指定してください',
      'User does not exist.': 'ユーザーが存在しません',
      'Incorrect username or password.': 'ユーザー名またはパスワードが違います',
      'User is not confirmed.': 'ユーザーは検証されていません',
      'User already exists': 'ユーザーは既に存在します',
      'Invalid verification code provided, please try again.':
        '指定された確認コードが無効です。もう一度お試しください',
      'Invalid password format': 'パスワードのフォーマットが不正です',
      'Invalid phone number format':
        '不正な電話番号フォーマットです。 電話番号は次のフォーマットで入力してください: +12345678900',
      'An account with the given email already exists.': 'そのメールアドレスは既に存在します',
      'Username cannot be empty': 'ユーザー名は必須です',
      'Password attempts exceeded': 'パスワード試行回数が超過しました',
      'Password cannot be empty': 'パスワードは必須入力です',
      'Attempt limit exceeded, please try after some time.':
        '試行制限を超過しました。しばらくしてからもう一度お試しください',
      'Username/client id combination not found.': 'ユーザーが存在しません',
      'CUSTOM_AUTH is not enabled for the client.': 'パスワードは必須です',
      'Password does not conform to policy: Password not long enough':
        'パスワードは8文字以上を入力してください (8文字以上の大文字小文字を含む英数字)',
      'Password does not conform to policy: Password must have uppercase characters':
        'パスワードには大文字を含めてください (8文字以上の大文字小文字を含む英数字)',
      'Password does not conform to policy: Password must have lowercase characters':
        'パスワードには小文字を含めてください (8文字以上の大文字小文字を含む英数字)',
      'Password does not conform to policy: Password must have numeric characters':
        'パスワードには数字を含めてください (8文字以上の大文字小文字を含む英数字)',
      "1 validation error detected: Value at 'password' failed to satisfy constraint: Member must have length greater than or equal to 6":
        'パスワードは8文字以上、大文字小文字を含む英数字を指定してください',
      'Password reset required for the user': 'パスワードリセット必須なユーザーです',
      'User cannot be confirmed. Current status is CONFIRMED': '認証済みユーザーです',
      'User is disabled.': '無効ユーザーです',
      'Invalid code provided, please request a code again.':
        '無効なコードが送信されました。もう一度コードをリクエストしてください。',
      'Invalid phone number format.': '電話番号の入力形式が不正です。ハイフンなしの電話番号の入力をお願い致します。',
    }

    if (conversions.hasOwnProperty(message)) {
      return conversions[message]
    }

    return message
  }
}

const cognito = new Cognito()

export default cognito
