<template>
  <div class="l-wrapper">
    <router-view />
  </div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'MainLayout',
})
</script>
<style lang="scss" scoped></style>
